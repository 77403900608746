/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Button from "../common/Button";
import ContactUs from "../common/ContactUs";
import Customers from "../common/Customers";
import Footer from "../common/Footer";
import Intro from "../common/Intro";
import Page from "../common/Page";
import Meta from "../common/Meta";
import CompanySection from "../home/HomeCompany";
import HomeHero from "../home/HomeHero";
import ProductsSection from "../home/HomeProducts";
import ServicesSection from "../home/HomeServices";
import {H1} from "../common/typography/Headings";

const IndexEn = () => (
    <Page lang="en" title="AI + Language" path="/en/">
        <Meta name="description">
            Atilika is a natural language processing and AI company with offices in Tokyo and Oslo.
            Leading businesses worldwide rely on our technology products and deep learning
            solutions.
        </Meta>
        <HomeHero>
            <H1>AI + language</H1>
        </HomeHero>
        <Intro>
            <p>
                Atilika is a natural language processing and AI company with offices in Tokyo and
                Oslo. Leading businesses worldwide rely on our technology products and deep learning
                solutions.
            </p>
        </Intro>
        <ServicesSection title="Services">
            <p>
                We work closely with our customers—as innovation partners—to jointly develop novel
                solutions to difficult problems in the areas of search, natural language processing,
                and machine learning. Leading companies worldwide trust us to to collaborate on
                their mission-critical features and products.
            </p>
            <p>
                <Button to="/en/services/" readMore>
                    more
                </Button>
            </p>
        </ServicesSection>
        <ProductsSection title="Products">
            <p>
                Atilika develops a suite of natural language processing products for East Asian and
                Western languages, including tools for morphological analysis, query suggestion,
                keyword extraction, and entity extraction.
            </p>
            <p>
                Our Japanese morphological analyzer, Kuromoji, is an industry standard which powers
                Japanese support in Apache Lucene and Apache Solr, and is also used by
                Elasticsearch.
            </p>
            <p>
                <Button to="/en/products/" readMore>
                    more
                </Button>
            </p>
        </ProductsSection>
        <CompanySection title="Company">
            <p>
                We are a small team of experienced software engineers based in Tokyo and Oslo.
                Atilika offers a combination of our own technology products and consulting services
                to customers.
            </p>
            <p>
                <Button to="/en/company/" white readMore>
                    more
                </Button>
            </p>
        </CompanySection>
        <Customers title="Our Customers" />
        <ContactUs title="Let’s get to work">
            <p>Contact us about your project today for a free consultation.</p>
        </ContactUs>
        <Footer lang="en" />
    </Page>
);

export default memo(IndexEn);
